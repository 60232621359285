.upload-select {
    width: 40%;
    text-align: left;
    margin-right: 20px;
    margin-bottom: 30px;
    margin-top: 10px;
    background-color: #232222;
    font-size: 18px;
}


.selected-box {
    background: white;
    padding: 5px;
    border-radius: 4px;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 10px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    max-width: 300px;
    max-height: 300px;
    height: auto;
    width: auto;
}

.edit-select {
    width: 95%;
    text-align: left;
    margin-bottom: 0;
    margin-top: 10px;
    background-color: #232222;
    font-size: 18px;
}

.loading {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-switch {
    background: gray;
}

.elements-input {
    margin: 10px 0;
}

.ant-upload-list-item {
    color: white;
}
.chat-button {
        font-size: 20px;
        height: auto;
}

.chat-text {
    color: white;
    margin-top: 20px;
    font-size: 20px;
}

.image-upload {
    width: 80%;
    height: 200px;
    border: 2px dashed gray;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}


@media (max-width: 576px) {
  #chat-title {
      font-size: 24px;
      width: 95%;
  }

    .select-label {
        font-size: 16px;
    }

    .upload-select{
        width: 80%;
        margin-bottom: 10px;
        margin-right: 0;
    }

    .edit-select {
        width: 80%;
    }

    .chat-button {
        font-size: 16px;
    }

    .chat-text {
        font-size: 16px;
    }

    .image-upload {
        width: 95%;
        height: 150px;
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
        max-width: 200px;
        max-height: 200px;
        height: auto;
        width: auto;
    }

    .loading {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}