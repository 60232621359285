.chat-box-container {
  width: 80%;
  height: 80vh;
  margin: 0 auto;
  /*background-color: #fff;*/
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
background: linear-gradient(to bottom, #234766, #3f8dc2);
/*background-size: 20px 20px;*/
/*background-color: #272727;*/
}

.chat-box-container .anticon {
  justify-content: center;
}

.chat-box {
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 20px;
  flex-direction: column;
}

.message-container {
  display: flex;
  align-items: flex-start;
}

.message-container-assistant {
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  border-bottom: 1px solid #505050;
  padding: 15px 0;
}

.message-container-user {
  display: flex;
  align-items: flex-start;
  background-color: #2e2f39;
  border-bottom: 1px solid #505050;
  padding: 15px 0;
}

.message .ant-card {
  background-color: transparent;
  margin-top: 15px;
  width: fit-content;
}

.message .ant-card-body {
  padding: 5px;
}

.chat-box .message {
  /*display: inline-block;*/
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #2e2f39;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  width: auto;
  padding-left: 15px;
  font-size: 16px;
  max-width: 85%;
  min-width: 30px;
  min-height: 30px;
  word-wrap: break-word;
}

.chat-box .avatar-user {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  margin-left: 5px;
}

.chat-box .avatar-reply {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  margin-left: 1px;

}



.chat-box .user {
  background-color: #4d9eff;
  color: #fff;
  align-self: flex-end;
}


.chat-box .message .content.right::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent #4d9eff transparent transparent;
}

.input-container {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
  border-top: 1px solid #d9d9d9;
}

.input {
  width: 100%;
  height: 100%;
  max-height: 150px;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: #fff;
}

.send-button {
  width: 80px;
  height: 40px;
  border-radius: 20px;
  background-color: #4d9eff;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
    margin-left: 10px;
}

.send-button:hover {
  background-color: #368be7;
    color: #fff;
}

@media (max-width: 576px) {
  .chat-box-container {
    width: 100%;
    background: transparent;
    box-shadow: none;
    overflow: auto;
  }

  .input-container {
    background-color: transparent;
    border: none;
        position: fixed;
    bottom: 0;
    width: 100%;
  }


  .chat-box {
    padding: 0 5px;
    border-radius: 0;
  }

  .chat-box .message {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0 0 0 10px;
    box-shadow: none;
  }

  .container {
    margin-top: 80px;
  }

  .send-button {
    background-color: transparent;
    margin-left: 1px;
  }

  .chat-box .avatar-user {
    width: 30px;
    height: 30px;
  }

  .chat-box .avatar-reply {
    width: 30px;
    height: 30px;
  }


}
