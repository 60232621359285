.credit-card {
     text-align: center;
    color: #3f8dc2;
    border-color: #3f8dc2;
    box-shadow: 0px 4px 8px rgb(1, 65, 94);
    background-color: transparent;
}

.user-quota {
    font-size: 22px;
    color: white;
    border: 1px solid rgba(217, 216, 216, 0.97);
    padding: 10px 30px;
    border-radius: 30px;
    width: fit-content;
}

.credit-card .ant-card-body {
    text-align: start;
    padding: 30px;
}

.usage-row .ant-card-head {
    color: white;
    font-size: 22px;
}

.ant-input::placeholder {
  color: #888; /* 设置你想要的颜色 */
}


#redemption {
    display: flex;
    margin-top: 10px;
}

.usage-row .ant-card-body {
    padding-top: 10px;
}

.usage-row .ant-card-body p {
    margin: 10px 0;
}

@media (max-width: 576px) {
    .credit-card {
        width: 90%;
    }

    .user-quota {
        font-size: 20px;
        color: white;
        border: 1px solid rgba(217, 216, 216, 0.97);
        padding: 10px 30px;
        border-radius: 30px;
        width: fit-content;
        margin: 0 5%;
    }
}