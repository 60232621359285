

#footer {
  width: 70%;
  height: 8vh;
  margin: 0 auto;
  margin-top: 30px;
  padding: 20px;
  border-top: 1px solid #d9d9d9;
  background-color: transparent;
}

.footer-left {
  font-size: 18px;
  color: #999;
}

.footer-right {
  display: flex;
  justify-content: end;
}

.footer-right a {
  margin-left: 10px;
    font-size: 18px;
}

.footer-text {
  color: #1677ff;
  font-size: 18px;
}

@media (max-width: 576px) {
  #footer {
    width: 95%;
    margin-top: 0;
    padding: 0;
  }

  .footer-left {
    font-size: 16px;
  }
  


  .footer-right {
    justify-content: center;
  }

  .footer-right a {
    font-size: 16px;
  }
}
