.room-card {
    /*width: 80%;*/
    /*height: 100%;*/
    background: #312f2f;
    margin-top: 20px;
    color: white;
    border: none;
    padding: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* 调整颜色透明度实现柔和的效果 */
    transition: box-shadow 0.3s ease; /* 添加过渡效果 */
}
.room-card .ant-card-body {
    padding: 0;
}

.room-image {
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 10 / 7;
}

.waiting-box {
    width: 100%;
    aspect-ratio: 10 / 7;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
}


.dark-pagination .ant-pagination-item,
.dark-pagination .ant-pagination-prev,
.dark-pagination .ant-pagination-next {
  background-color: #5d5d5d;
  border-color: #666;
    color: white;
}


.dark-pagination svg {
    color: white;
}

.dark-pagination .ant-pagination-item a {
    color: white;
}

.dark-pagination .ant-pagination-item-active {
  background-color: #0088ff;
  border-color: #c2c2c2;
}

.dark-pagination .ant-pagination-disabled {
  background-color: #333;
  border-color: #666;
  color: #999;
}


.card-text {
   color: dodgerblue;
    font-size: 24px;
    font-weight: bold;
    margin: 0;

}

@media (max-width: 576px) {
  .room-card {
    width: 98%;
  }
    .room-image {
        border-radius: 10px;
        aspect-ratio: 10 / 7;
    }

    .waiting-box {
        width: 100%;
        aspect-ratio: 10 / 7;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-text {
        font-size: 20px;
        height: 0;
    }

    #design-title {
      font-size: 30px;
    }

}
