.use-container {
  animation: flyInFromLeft 1s forwards;
}

.chat-container {
  width: 100%;
  margin: 0 auto;
  animation: flyInFromRight 1s forwards;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 6px 20px rgba(0, 0, 0, 0.06);
  background-color: #343333;

}
/* 设置 Steps 组件的字体大小和颜色 */
.ant-steps-item-title {
  font-size: 26px;
  color: #fff !important;
}
.use-case-card {
  background-color: #343333;
  box-shadow: 0 8px 16px rgb(80, 80, 80);
  margin: 0 20px;
}

.use-case-card.ant-card-bordered {
  border-color: #343333
}

.use-case-card .ant-card-body {
  padding: 20px;
}

/* 设置 Steps 组件的当前步骤的字体大小和颜色 */
.ant-steps-item-process .ant-steps-item-title {
  font-size: 26px;
  color: #fff !important;
}

/* 设置 Steps 组件中未选中步骤的图标颜色为灰色 */
.ant-steps-item:not(.ant-steps-item-process) .ant-steps-item-icon > .ant-steps-icon {
  color: #ccc !important;
}


/* 设置 Steps 组件中未选中步骤的文本颜色为灰色 */
.ant-steps-item:not(.ant-steps-item-process) .ant-steps-item-title {
  color: #ccc !important;
}


.chat {
  border: 1px solid #081c31;
  border-radius: 8px;
  overflow: hidden;
}

.message {
  padding: 10px;
}

.chat-text {
  font-size: 18px;
}

.received {
  /*background-color: #7a7a7a;*/
  display: flex;
}

.sent {
  /*background-color: #081c31;*/
  color: white;
  text-align: right;
  display: flex;
  justify-content: end;
    align-items: flex-start;

}

.custom-carousel .slide {
  transition: transform 0.5s ease, filter 0.5s ease;
  cursor: pointer;
}

.custom-carousel .inactive-slide {
  transform: scale(0.65);
}

.custom-carousel .active-slide {
  transform: scale(1);
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ant-collapse {
  background-color: transparent;
  border: 1px solid #9a9999;
  width: 95%;
  text-align: left;
  flex: none;
}


.faq-container .ant-collapse-content-box {
  color: dodgerblue;
  /*background: #0d6cc2;*/
  margin: 0;
  margin-left: 30px;
  border-radius: 8px;
  font-size: 20px;
}



#home-title {
  animation-name: flyIn-top;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  font-size: 50px;
  font-family: 'Courgette', cursive;
  margin-top: 0px;
}

.home-text {
    color: #888888;
    font-size: 24px;
  }

#home-buttom {
  animation-name: flyIn-bottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  font-size: 20px;
}

#home-row {
  height: 350px;
  background: radial-gradient(40% 40%, #0B5884, #232222);
}

.col-title {
  animation-name: flyIn-top;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}


.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  align-items: center;
  color: #fff; /* 设置文字颜色为白色 */
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 576px) {
  #home-title {
    font-size: 22px;
  }

  #home-buttom {
    font-size: 16px;
  }

  .col-title .title-text {
    font-size: 30px;
  }

  .home-text {
    color: #888888;
    font-size: 16px;
  }

  #home-row {
    height: 150px;
  }
  .faq-container {
    color: white;
  }

  .chat-container {
    padding-top: 5px;
  }
  .faq-container .ant-collapse-content-box {
    font-size: 16px;
  }

  .ant-collapse>.ant-collapse-item >.ant-collapse-header {
    font-size: 16px;
  }

}