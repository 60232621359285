.menu-item:hover {
  transform: translateX(5px);
  transition: all 0.2s ease-in-out;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 15%;
  height: 8vh;
  line-height: 0;
  background-color: #1c1c1c;
  border-bottom: 2px solid #333;
}

.logo {
  height: 50px;
  margin: 10px;
  background-size: contain;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  width: 100%;
}

.language-dropdown .ant-dropdown-menu {
  background-color: transparent !important; /* 设置下拉菜单背景为透明 */
  color: white !important; /* 设置下拉菜单项的文字颜色为白色 */
}

.language-button {
  color: black !important; /* 设置按钮文字颜色为黑色 */
  background-color: transparent !important; /* 设置按钮背景为透明 */
  border: none !important; /* 移除按钮边框 */
  margin-left: 10px;
}

.menu-user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  padding: 0 20px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.menu .ant-menu-item:hover {
  background-color: #053f75;
   border-radius: 10px;

}

.menu .ant-menu-item-selected {
  background-color: transparent !important;
}


.menu-item:hover {
  transform: translateX(5px);
}

.user-login {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.user-icon {
  font-size: 16px;
  margin-right: 5px;
}

.user-text {
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
}

#logout-icon{
  height: 32px;
  width: 32px;
}

.ant-menu-title-content {
  color: #1677ff;
}

#logout-button {
  border: none;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  color: darkgrey;
  margin-left: 10px;
  width: 100%;
}

.user-info > * {
  left: 10px;
}
.nickname {
  margin: 0 10px;
  font-size: 18px;
}

@media (max-width: 576px) {
  .logo {
    margin: 0;
    height: 40px;
  }
  .user-login {
    font-size: 16px;
  }
  .header {
    padding: 7% 5%;
  }

  .nickname {
    display: none;
  }

  .user-info {
    border-left: none;
  }

  .dark-theme {
    background-color: #282c34;
    color: #fff;
  }



  .menu-user .ant-drawer .ant-drawer-header {
    padding: 15px 5%;
  }

  .menu-user .ant-drawer .ant-drawer-body {
    padding: 24px 5%;
  }
}
